import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import ShareLink from "./ShareLink";
import YoutubeUrlAndNumberOfViews from "./YoutubeUrlAndNumberOfViews";

class UploadToSocialMedia extends React.Component {
    render() {
        const {
            facebookPages,
            facebookUrl,
            setActiveComponent,
            youtubeAuthorisationUrl,
            youtubeUrl,
            youtubeChannel,
            videoUrlWithoutToken,
            youtubeStats
        } = this.props;

        return <div id="video-actions" className="large-margin-bottom">
            <Row>
                <Col>
                    <div className="medium-margin-top medium-margin-bottom">
                        <div className="large-margin-bottom">
                            {
                                !facebookPages &&
                                !facebookUrl &&
                                <div className="small-margin-bottom">
                                    <p>Login with Facebook to post this video to your page.</p>
                                    <Button variant="primary" onClick={() => setActiveComponent('facebookAuthorisation')}>
                                        <i className="fab fa-facebook-f small-margin-right" aria-hidden="true"></i>Login with Facebook
                                    </Button>
                                </div>
                            }
                            {
                                facebookPages &&
                                <div className="small-margin-bottom">
                                    <p>You are logged in! Click the below button to post this video to your facebook page.</p>
                                    <Button variant="primary" onClick={() => setActiveComponent('facebookUploadForm')}>
                                        <i className="fab fa-facebook-f small-margin-right" aria-hidden="true"></i>Continue with Facebook
                                    </Button>
                                </div>
                            }
                            {
                                youtubeAuthorisationUrl &&
                                !youtubeUrl &&
                                <div className="small-margin-bottom">
                                    <Button variant="danger" onClick={() => setActiveComponent('youtubeAuthorisation')}>
                                        <i className="fab fa-youtube small-margin-right" aria-hidden="true"></i>Authorise to Youtube
                                    </Button>
                                    { <ShareLink videoLink={ videoUrlWithoutToken }/> }
                                </div>
                            }
                            {
                                youtubeChannel &&
                                !youtubeAuthorisationUrl &&
                                !youtubeUrl &&
                                <div className="small-margin-bottom">
                                    <Button variant="danger" onClick={() => setActiveComponent('youtubeUploadForm')}>
                                        <i className="fab fa-youtube small-margin-right" aria-hidden="true"></i>Upload to Youtube
                                    </Button>
                                    { <ShareLink videoLink={ videoUrlWithoutToken }/> }
                                </div>
                            }
                        </div>
                        {
                            facebookUrl &&
                            <div className="small-margin-bottom">Click to view the video on Facebook: <a href={facebookUrl} target="_blank" rel="noopener noreferrer">{facebookUrl}</a></div>
                        }
                        {
                            youtubeUrl && <YoutubeUrlAndNumberOfViews youtubeUrl={youtubeUrl} youtubeStats={youtubeStats}/>
                        }
                    </div>
                </Col>
            </Row>
        </div>

    }
}

export default UploadToSocialMedia;