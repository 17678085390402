import React from 'react';

class RevisionVideoPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            videoElementId: 'revision-player-video'
        };

    }

    componentDidMount() {
        this.initVideoPlayer();
    }

    componentWillUnmount() {
        let VisdomPlayer = window.VisdomPlayer;

        if (Object.keys(VisdomPlayer.players()).includes(this.state.videoElementId)) {
            VisdomPlayer.disposeVideoById(this.state.videoElementId);
        }
    }

    initVideoPlayer() {
        if (this.props.mp4VideoRevisionVersionUrl) {
            setTimeout(() => {
                window.VisdomPlayer.initVideoById(this.state.videoElementId);
            }, 200);
        }
    }

    render() {
        const {mp4VideoRevisionVersionUrl} = this.props;

        return (
            <div id="revision-player">
                {
                    mp4VideoRevisionVersionUrl ?
                        <video id={this.state.videoElementId} className="video-js visdom-video" controls preload="auto">
                            <source src={mp4VideoRevisionVersionUrl} type="video/mp4"/>
                        </video>
                        :
                        <div id="revision-video-not-found" className="hidden">Revision video not found.</div>
                }
            </div>
        )
    }
}

export default RevisionVideoPlayer;