import React from 'react';
import ShareThis from "./ShareThis";
import {Col, Row} from "react-bootstrap";
import EmbedCode from './EmbedCode';
import QrCode from './QrCode';
import DownloadLinks from './DownloadLinks';

class BasicDownloadAndShare extends React.Component {
    render() {
        const {
            canDownloadVideo,
            videoUrlWithoutToken,
            title,
            cdnMedia
        } = this.props;

        return <div>
            <div id="sharing-and-actions-section" className="text-center large-margin-bottom">
                <ShareThis canDownloadVideo={canDownloadVideo} videoUrlWithoutToken={videoUrlWithoutToken} title={title}/>
            </div>
            <div id="video-actions" className="large-margin-bottom">
                <Row>
                    <Col>
                        {  canDownloadVideo && cdnMedia && <DownloadLinks cdnMedia={cdnMedia}/> }
                        <EmbedCode videoUrlWithoutToken={videoUrlWithoutToken}/>
                        <div className="clearfix"/>
                        <QrCode videoUrlWithoutToken={videoUrlWithoutToken}/>
                    </Col>
                </Row>
            </div>
        </div>
    }

}

export default BasicDownloadAndShare;