import React from "react";
import QueryString from "query-string";
import { Row, Col } from "react-bootstrap";
import VideoPlayer from "./VideoPlayer";
import VideoActions from "./VideoActions";
import VideoActionButton from "./VideoActionButton";
import Header from "./Header";
import Footer from "./Footer";
import NotFound from "./NotFound";
import Loader from "./Loader";
import axios from "../_config/axios";
import MetaTags from "react-meta-tags";

class Video extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isValidVideo: true,
      videoId: "",
      token: "",
      videoData: ""
    };

    this.renderVideo = this.renderVideo.bind(this);
  }

  componentDidMount() {
    const query = QueryString.parse(this.props.location.search);
    const videoId = this.props.match.params.id;
    const token = query.token;

    this.setState({ videoId, token });

    // Load video data via api call.
    axios
      .get(
        process.env.REACT_APP_API_URL +
          `videos/` +
          videoId +
          (token ? "?token=" + token : "")
      )
      .then(res => {
        const videoData = res.data;
        this.setState({ isLoading: false, videoData });
      })
      .catch(
        function() {
          this.setState({ isLoading: false, isValidVideo: false });
        }.bind(this)
      );
  }

  /**
   * Render loader.
   */
  renderLoader() {
    return (
      <div className="wide-container">
        <div className="text-center extra-large-margin-top">
          <Loader />
        </div>
      </div>
    );
  }

  /**
   * Render video.
   */
  renderVideo() {
    return (
      <div id="main">
        <MetaTags>
          <title>{this.state.videoData.title}</title>
          <meta property="og:title" content={this.state.videoData.title} />
          <meta property="og:type" content="movie" />
          <meta
            property="og:url"
            content={window.location.href.split("?")[0]}
          />
          <meta property="og:site_name" content={this.state.videoData.title} />
          {/*Currently for vms meta cdn there is no thumb support, may need this later*/}
          <meta property="og:image" content=">" />
          <meta
            property="og:video:type"
            content="application/x-shockwave-flash"
          />
          <meta property="og:video:type" content="video/mp4" />
        </MetaTags>
        <Header />
        <section>
          {this.state.isValidVideo ? (
            <div className="wide-container">
              <div className="video-title">{this.state.videoData.title}</div>
              <Row>
                <Col lg="8" className="medium-margin-bottom">
                  <VideoPlayer video={this.state.videoData} />
                  {this.state.token ? (
                    <VideoActionButton
                      videoId={this.state.videoId}
                      token={this.state.token}
                      videoData={this.state.videoData}
                    />
                  ) : null}
                </Col>
                <Col lg="4" className="large-padding-left video-action-wrapper">
                  <VideoActions
                    video={this.state.videoData}
                    videoId={this.state.videoId}
                    token={this.state.token}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <NotFound />
          )}
        </section>
        <Footer />
      </div>
    );
  }

  render() {
    return this.state.isLoading ? this.renderLoader() : this.renderVideo();
  }
}

export default Video;
