import React from 'react';

function Footer() {
    return (
        <footer>
            <div className="footer-contents clearfix">
                <div className="wide-container">
                    <div className="extra-large-padding-top small-padding-bottom">
                        <div className="row">
                            <div className="col-sm-6 small-margin-bottom">
                                <p className="social-icons">
                                    <a href="https://www.facebook.com/VisualDomainAustralia" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                    <a href="https://www.youtube.com/user/VisualDomainMedia" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/company/visual-domain" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin" aria-hidden="true"></i></a>
                                    <a href="https://twitter.com/visualdomain" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                    <a href="https://www.instagram.com/visual.domain/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" aria-hidden="true"></i></a>
                                </p>
                                <p className="copy-right">&copy; Visual Domain Australia Pty Ltd / All Rights Reserved.</p>
                                <p className="info-links">
                                    <a href="https://www.visualdomain.com.au/about">About Us</a><span className="extra-small-margin-left extra-small-margin-right">|</span>
                                    <a href="http://visualdomain.com.au/contact-us/">ContactUs</a>
                                </p>
                            </div>
                            <div className="col-sm-6 text-right">
                                <h4 className="text-uppercase">Contact Us</h4>
                                <div className="account-manager-info">
                                    <p><a href="mailto:info@visualdomain.com.au">info@visualdomain.com.au</a></p>
                                    <p><a href="http://visualdomain.com.au">visualdomain.com.au</a></p>
                                    <p><a href="tel:1300040718">1300 040 718</a></p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;