import React from "react";
import Loader from "./Loader";
import DownloadAndShare from "./DownloadAndShare";
import FacebookAuthorisation from "./FacebookAuthorisation";
import FacebookUploadForm from "./FacebookUploadForm";
import YoutubeAuthorisation from "./YoutubeAuthorisation";
import YoutubeUploadForm from "./YoutubeUploadForm";
import * as OrderJobStatus from "../_constants/orderJobStatus";
import CaptionOfferModal from "./captions/CaptionOfferModal";
import CaptionVideoModal from "./captions/CaptionVideoModal";
import Cookies from "universal-cookie";
import { isEmpty } from "lodash";

class VideoApprovedOrArchived extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      videoUrlWithoutToken: "",
      activeComponent: "downloadAndShare",
      showCaptionOfferModal: false,
      showVideoModal: false,
      videoId: null,
      cookies: null,
    };

    this.setActiveComponent = this.setActiveComponent.bind(this);
    this.renderLoader = this.renderLoader.bind(this);
    this.closeCaptionOfferModal = this.closeCaptionOfferModal.bind(this);
    this.showCaptionOfferModal = this.showCaptionOfferModal.bind(this);
    this.showVideoModal = this.showVideoModal.bind(this);
    this.closeVideoModal = this.closeVideoModal.bind(this);
  }

  componentDidMount() {
    const videoUrlWithoutToken =
      window.location.origin + "/" + this.props.video.id;

    this.setState({ isLoading: false, videoUrlWithoutToken });

    // Initialise cookies.
    this.cookies = new Cookies();
    // Set video id.
    this.videoId = this.props.video.id;
    // Get video cookie.
    let videoCookie = this.cookies.get('vlp_vc_' + this.videoId);

    if (
      this.props.token !== undefined &&
      this.props.actions.order_job_status === OrderJobStatus.APPROVED &&
      this.props.video.video_captions.length === 0 &&
      isEmpty(videoCookie) === true && this.videoId !== videoCookie
    ) {
      setTimeout(() => {
        this.showCaptionOfferModal();
      }, 8000);
    }
  }

  setActiveComponent(value) {
    this.setState({
      activeComponent: value
    });
  }

  showCaptionOfferModal() {
    // Pause the main video player.
    const { html_video: mainVideoPlayer } = window.VisdomPlayer.players();
    mainVideoPlayer.pause();

    this.setState({ showCaptionOfferModal: true });
  }

  closeCaptionOfferModal() {
    this.setState({ showCaptionOfferModal: false });

    // Set video cookies to 1 week.
    let expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 7);
    this.cookies.set('vlp_vc_' + this.videoId, this.videoId, {expires: expiryDate});
  }

  showVideoModal() {
    this.setState({
      showVideoModal: true
    });
  }

  closeVideoModal() {
    this.setState({ showVideoModal: false });
  }

  renderLoader() {
    return (
      <div className="wide-container">
        <div className="text-center extra-large-margin-top">
          <Loader />
        </div>
      </div>
    );
  }

  renderActions() {
    if (
      this.state.activeComponent === "facebookAuthorisation" &&
      this.props.actions.facebook_authorisation_url
    ) {
      return (
        <FacebookAuthorisation
          setActiveComponent={this.setActiveComponent}
          facebookAuthorisationUrl={
            this.props.actions.facebook_authorisation_url
          }
        />
      );
    }

    if (
      this.state.activeComponent === "facebookUploadForm" &&
      this.props.actions.facebook_pages
    ) {
      return (
        <FacebookUploadForm
          setActiveComponent={this.setActiveComponent}
          facebookPages={this.props.actions.facebook_pages}
          updateVideoActions={this.props.updateVideoActions}
          token={this.props.token}
          video={this.props.video}
        />
      );
    }

    if (
      this.state.activeComponent === "youtubeAuthorisation" &&
      this.props.actions.youtube_authorisation_url
    ) {
      return (
        <YoutubeAuthorisation
          setActiveComponent={this.setActiveComponent}
          youtubeAuthorisationUrl={this.props.actions.youtube_authorisation_url}
          youtubeUrl={this.props.actions.youtube_url}
          updateVideoActions={this.props.updateVideoActions}
          token={this.props.token}
          youtubeChannel={this.props.actions.youtube_channel}
          video={this.props.video}
        />
      );
    }

    if (
      this.state.activeComponent === "youtubeUploadForm" &&
      this.props.actions.youtube_channel
    ) {
      return (
        <YoutubeUploadForm
          setActiveComponent={this.setActiveComponent}
          updateVideoActions={this.props.updateVideoActions}
          token={this.props.token}
          youtubeChannel={this.props.actions.youtube_channel}
          video={this.props.video}
        />
      );
    }

    return (
      <>
        <DownloadAndShare
          canDownloadVideo={this.props.actions.can_download_video}
          videoUrlWithoutToken={this.state.videoUrlWithoutToken}
          title={this.props.video.title}
          cdnMedia={this.props.actions.cdn_media}
          orderJobStatus={this.props.actions.order_job_status}
          token={this.props.token}
          videoId={this.props.video.id}
          updateVideoActions={this.props.updateVideoActions}
          setActiveComponent={this.setActiveComponent}
          facebookPages={this.props.actions.facebook_pages}
          facebookUrl={this.props.actions.facebook_url}
          youtubeAuthorisationUrl={this.props.actions.youtube_authorisation_url}
          youtubeUrl={this.props.actions.youtube_url}
          youtubeChannel={this.props.actions.youtube_channel}
          youtubeStats={this.props.actions.youtube_stats}
          video={this.props.video}
        />
        <CaptionOfferModal
          showCaptionOfferModal={this.state.showCaptionOfferModal}
          closeCaptionOfferModal={this.closeCaptionOfferModal}
          showVideoModal={this.showVideoModal}
        />
        <CaptionVideoModal
          showVideoModal={this.state.showVideoModal}
          closeVideoModal={this.closeVideoModal}
        />
      </>
    );
  }

  render() {
    return this.state.isLoading ? this.renderLoader() : this.renderActions();
  }
}

export default VideoApprovedOrArchived;
