import React from "react";
import { ARCHIVED } from "../_constants/orderJobStatus";
import GoBack from "./GoBack";
import BasicDownloadAndShare from "./BasicDownloadAndShare";
import UploadToSocialMedia from "./UploadToSocialMedia";

class DownloadAndShare extends React.Component {
  render() {
    const {
      canDownloadVideo,
      videoUrlWithoutToken,
      cdnMedia,
      token,
      updateVideoActions,
      orderJobStatus,
      setActiveComponent,
      facebookPages,
      facebookUrl,
      youtubeAuthorisationUrl,
      youtubeUrl,
      youtubeChannel,
      youtubeStats,
      video
    } = this.props;
    const { title, id: videoId } = video;

    return (
      <div>
        <BasicDownloadAndShare
          canDownloadVideo={canDownloadVideo}
          videoUrlWithoutToken={videoUrlWithoutToken}
          title={title}
          cdnMedia={cdnMedia}
        />
        <UploadToSocialMedia
          facebookPages={facebookPages}
          facebookUrl={facebookUrl}
          setActiveComponent={setActiveComponent}
          youtubeAuthorisationUrl={youtubeAuthorisationUrl}
          youtubeUrl={youtubeUrl}
          youtubeChannel={youtubeChannel}
          videoUrlWithoutToken={videoUrlWithoutToken}
          youtubeStats={youtubeStats}
        />
        {orderJobStatus !== ARCHIVED && (
          <GoBack
            token={token}
            videoId={videoId}
            updateVideoActions={updateVideoActions}
          />
        )}
      </div>
    );
  }
}

export default DownloadAndShare;
