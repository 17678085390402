import React from "react";
import VisualDomainLogo from "../_assets/images/visual-domain-logo-on-dark.png";

function Header() {
  return (
    <header>
      <div className="brand-logo">
        <a href="https://www.visualdomain.com.au" target="_blank">
            <img className="logo" src={VisualDomainLogo} alt="Visual Domain" />
        </a>
      </div>
    </header>
  );
}

export default Header;
